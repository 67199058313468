import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import axios from "axios";
import VueMeta from "vue-meta";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faTiktok } from "@fortawesome/free-brands-svg-icons";
import { faFacebook } from "@fortawesome/free-brands-svg-icons";
import { faInstagram } from "@fortawesome/free-brands-svg-icons";
import { faTwitter } from "@fortawesome/free-brands-svg-icons";
import { faYoutube } from "@fortawesome/free-brands-svg-icons";
import { faFlickr } from "@fortawesome/free-brands-svg-icons";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { faArrowUp} from "@fortawesome/free-solid-svg-icons";
import { faArrowDown } from "@fortawesome/free-solid-svg-icons";
import { faPlayCircle } from "@fortawesome/free-solid-svg-icons";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import { faGlobe } from "@fortawesome/free-solid-svg-icons";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { faVolumeUp } from "@fortawesome/free-solid-svg-icons";
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import JQuery from "jquery";
import VueSocialSharing from 'vue-social-sharing'
import VueAnalytics from 'vue-analytics';
import VueGtag from "vue-gtag";
import VueLodash from 'vue-lodash'
import lodash from 'lodash'

library.add(
  faTiktok,
  faFacebook,
  faInstagram,
  faTwitter,
  faYoutube,
  faFlickr,
  faEnvelope,
  faUser,
  faGlobe,
  faArrowLeft,
  faChevronRight,
  faDownload,
  faCheck,
  faTimes,
  faPlayCircle,
  faArrowDown,
  faArrowUp,
  faSearch,
  faVolumeUp
);

Vue.component("font-awesome-icon", FontAwesomeIcon);
Vue.use(VueLodash, { name: 'custom' , lodash: lodash })
Vue.config.productionTip = false;
Vue.use(VueMeta);
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(VueSocialSharing);
Vue.use(VueAnalytics, {
  id: 'G-FWW1XH5NKK',
  router
});
Vue.filter('filterByMonth', function(posts, selectedMonth) {
  if (selectedMonth === 0) {
    return posts; // Return all
  }
  return posts.filter(post => {
    const postDate = new Date(post.date);
    const postMonth = postDate.getMonth() + 1; // Months are indexed from 0
    return postMonth === selectedMonth;
  });
});
Vue.use(VueGtag, {
  config: { id: "G-FWW1XH5NKK" },
  includes: [
    { id: 'G-FWW1XH5NKK' }
  ]
}, router);

new Vue({
  router,
  store,
  axios,
  JQuery,
  render: (h) => h(App),
}).$mount("#app");
