<template>
  <div class="sback">
    <b-container>
      <b-row class="sponsors">
        <b-col>
          <h3 class="title">
            Partners & Suppliers
          </h3>
          <b-carousel id="carousel-1" :interval="4000" img-width="1024">
            <b-carousel-slide
              img-blank
            >
              <b-row>
                <b-col v-for="(spon, index) in partnerFilter" :key="index">
                  <b-link :href="spon.acf.url" target="_blank" class="noUnderline">
                    <b-img
                      class="spLogo"
                      :src="spon.acf.footerimage"
                    ></b-img>
                  </b-link>
                </b-col> </b-row
            ></b-carousel-slide>
            <!-- <b-carousel-slide
              img-blank
            >
              <b-row>
                <b-col v-for="(spon, index) in supplierFilter" :key="index">
                  <b-link :href="spon.acf.url" target="_blank">
                    <b-img
                      class="spLogo"
                      :src="spon.acf.logo"
                    ></b-img>
                  </b-link>
                </b-col> </b-row
            ></b-carousel-slide> -->
          </b-carousel>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "sponsors",
  data() {
    return {
      tourSuppliers: [],
      tsRowTwo: [],
    };
  },
  computed: {
    supplierFilter: function() {
      return this.tsRowTwo.filter(
        (partner) => partner.acf.partner === true
      );
    },
    partnerFilter: function() {
      return this.tourSuppliers.filter(
        (partner) => !partner.acf.supplier === true
      );
    },
  },
  mounted() {
    axios
      .get(process.env.VUE_APP_WPAPI_URL+"wp/v2/toursuppliers")
      .then((response) => {
        this.tourSuppliers = response.data;
        return axios.get(
          process.env.VUE_APP_WPAPI_URL+ "wp/v2/tourpartners"
        );
      })
      .then((response) => {
        this.tsRowTwo = response.data;
      });
  },
};
</script>

<style scoped>
.noUnderline:hover {
  text-decoration: none;
}
.sback {
  background-color: #95c93d;
  padding: 10px 0;
}
.spLogo {
  width: 130px;
  height: auto;
  margin-top: 30px;
  margin-bottom: 0;
}
.title {
  position: absolute;
  left: 0;
  color: #fff;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
}
::v-deep .carousel-inner {
  height: 135px;
}

::v-deep .carousel-caption{
      top: 0;
      right: auto;
      left: auto;
}
</style>
