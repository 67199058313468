<!-- Nav Menu -->

<template>
  <div>
    <template v-if="$route.name == 'home'">
      <div id="app" :style="{ backgroundImage: 'url(' + takeover + ')' }" :class="{parallax: takeover !== ''}" class="overflowAll">
        <Menu
          @season="setSeason"
          @course="setCourse"
          @report="setReport"
          @title="setTitle"
          :config="env"
        />
        <transition name="fade" appear>
          <router-view
            :key="$route.fullPath"
            :season="season"
            :course="course"
            :report="report"
            :title="title"
            @takeover="setTakeOver"
            :config="env"
        /></transition>
        <Footer :season="season" :config="env"/>
      </div>
    </template>
    <template v-else>
      <div id="app" class="overflowAll">
        <Menu
         @season="setSeason"
          @course="setCourse"
          @report="setReport"
          @title="setTitle"
          :config="env"
        />
        <transition name="fade" appear>
          <router-view
            :key="$route.fullPath"
            :season="season"
            :course="course"
            :report="report"
            @takeover="setTakeOver"
            :config="env"
        /></transition>
        <Footer :season="season" :config="env"/>
      </div>
    </template>
  </div>
</template>


<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter-active {
  transition-delay: 0.5s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
#app {
  font-family: "oswald", Helvetica, Arial !important;
  font-size: 14pt;
}
li.nav-item::marker {
  color: transparent;
}
.parallax{
  min-height: 500px;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
@media only screen and (max-width: 768px) {
  .overflowAll {
    overflow: hidden;
  }
}
@media only screen and (max-width: 480px) {
  #app {
    font-size: 10pt;
  }
}
</style>
<script>
  <meta name="apple-itunes-app" content="app-id=419334047"></meta>
</script>
<script>
import Footer from "@/components/footer.vue";
import Menu from "@/components/menu.vue";

export default {
  components: {
    Footer,
    Menu,
  },
  data() {
    return {
      season: "",
      report: "",
      course: "",
      title:'',
      takeover:'',
      env:[]
    };
  },
  methods: {
    // Define method that will use the payload to update the data property
    setCourse(payload) {
      this.course = payload.course;
    },
    setSeason(payload) {
      this.season = payload.season;
    },
    setReport(payload) {
      this.report = payload.report;
    },
    setTitle(payload) {
      this.title = payload.title;
    },
    setTakeOver(payload) {
      this.takeover = payload.takeover;
    },
  },
  mounted(){
    this.env = process.env
  }
};
</script>
